import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {numFormatElement, StyledTableCell} from "../Utils/listItems";
import DataService from "../services/DataService";



export default function StatisticsTable(props) {

    const [summaryData, setSummaryData] = useState([]);

    useEffect(() => {
        if (props.project !== "" && props.port !== ""  )  {
            DataService.fetchStatsData(props.authToken,props.project, props.port)
                .then(res => setSummaryData(res));
        }
    }, [props]);

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{textAlign: 'left'}}>Metric</StyledTableCell>
            <StyledTableCell>All</StyledTableCell>
            <StyledTableCell>Long</StyledTableCell>
            <StyledTableCell>Short</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryData.map((row, index) => (
            <TableRow key={index}>
              <StyledTableCell  style={{textAlign: 'left'}}>{row.Metric}</StyledTableCell>
              <StyledTableCell>{numFormatElement(row.AllTrades)}</StyledTableCell>
              <StyledTableCell>{numFormatElement(row.LongTrades)}</StyledTableCell>
              <StyledTableCell>{numFormatElement(row.ShortTrades)}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
