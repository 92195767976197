import React, {useEffect, useState, useCallback} from 'react';
import Title from './Title';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import Container from "@material-ui/core/Container";
import DataService from "../services/DataService";
import useTheme from "@material-ui/core/styles/useTheme";
import {CircularIndeterminate} from "../Utils/listItems";


export default function BarChart(props) {
    const theme = useTheme();

    const getChart = useCallback((data, title,ylabel,yunit) => {

    
        return {
            chart: {
                type: 'column',
                backgroundColor: theme.chart.background
            },
            title: {
                text: title,
                align: 'center',
                style: {
                    color: theme.chart.text
                },
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        color: theme.chart.text,
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ylabel,
                    style: {
                        color: theme.chart.text
                    },
                },
                labels: {
                    style: {
                        color: theme.chart.text,
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            tooltip: {
                valueSuffix: yunit
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    },
                    backgroundColor: theme.chart.background
                }
            },
            // legend: {
            //     layout: 'vertical',
            //     align: 'right',
            //     verticalAlign: 'top',
            //     x: -40,
            //     y: 80,
            //     floating: true,
            //     borderWidth: 1,
            //     backgroundColor: "white",
            //     shadow: false
            // },
            legend: {
                enabled: false
                // layout: 'vertical',
                // align: 'right',
                // verticalAlign: 'top',
                // x: -40,
                // y: 80,
                // floating: true,
                // borderWidth: 1,
                // backgroundColor: "white",
                // shadow: false
            },
            credits: {
                enabled: false
            },
            series: [{
                showInLegend: false,       
                name: props.ylabel,
                data: data
            }]
        }}, [theme]);

    const [chartOptions, setChartOptions] = useState(getChart([0,0]));
    const [isLoading, setIsLoading] = useState(false);
    const [chartDataPort, setChartDataPort] = useState([0,0]);

    useEffect(() => {
        if (props.project === "" || props.port === "" || props.fetchfn !== "fetchAggPortTheme" ||  props.authToken === '') return;
        setIsLoading(true);
        DataService.fetchAggPortTheme(props.authToken,props.project, props.port)
            .then(res => {
                let data = [];               
                if (props.type === "Style"){
                    data = res;}
                data =  data.map((data) =>
                    [data.name, data.values]
                        // [data.name, data.y]
                );
                setChartDataPort(data);
            }).then( res => {
            setIsLoading(false);
        });
    }, [props.project, props.port, props.fetchfn, props.type, props.ylabel]);

    
    useEffect(() => {
        if (props.project === "" || props.port === "" || props.fetchfn !== "fetchBotConfigGroupingsPerf" ||  props.authToken === '')  return;
        setIsLoading(true);
        DataService.fetchBotConfigGroupingsPerf(props.authToken,props.project, props.port, props.type)
            .then(res => {
                let data = [];
                if (props.type === "Sector"){
                    // noinspection JSUnresolvedVariable
                    data = res.Sector;}
                if (props.type === "Industry"){
                    data = res.Industry;}
                if (props.type === "AssetClass"){ // AssetClass should be displayed with a space
                    data = res.AssetClass;}
                if (props.type === "Securities"){
                    data = res.Securities;}               
                if (props.type === "Style"){
                    data = res;}
                data =  data.map((data) =>
                    // [data.name, data.values]
                    [data.name, data.y]
                );
                setChartDataPort(data);
            }).then( res => {
            setIsLoading(false);
        });
    }, [props.project, props.port, props.fetchfn, props.type, props.ylabel]);


    useEffect(() => {
        if (props.project === "" || props.port === "" || chartDataPort === [0,0] )  return;
        setIsLoading(true);
        console.log('test ylabel: ', props.ylabel)

        let yunit = "";
        if (props.ylabel.includes("Return")){
            yunit = "%"
        }
            else{
            yunit = ""
        }

        setChartOptions(getChart(chartDataPort, props.type, props.ylabel,yunit))
        setIsLoading(false);
    }, [props.project, props.port, chartDataPort, props.type, props.ylabel, getChart]);

    return (
        <React.Fragment>
            <Container maxWidth={false} disableGutters={true}>
                {isLoading ? <Container style={{minHeight: 800, textAlign: "center"}}><CircularIndeterminate/></Container>
                    :
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'chart'}
                        options={chartOptions}
                        containerProps={{style: {width: "100%" , fill: 'black'}}}
                    /> }
            </Container>
        </React.Fragment>
    );
}
