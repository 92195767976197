import React, {useEffect, useState, useCallback} from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import Container from "@material-ui/core/Container";
import useTheme from "@material-ui/core/styles/useTheme";
import {CircularIndeterminate} from "../Utils/listItems";

export default function BaseChart(props) {
    const theme = useTheme();

    const getChart = useCallback((chartSeries) => {
        return {
            chart: {
                height: 800,
                // width: 15000,
                type: 'line',
                backgroundColor: theme.chart.background,
            },
            xAxis: {
                gridLineWidth: 1,
                gridLineColor: theme.chart.gridLine,
                labels: {
                    staggerLines: 2,
                    style: {
                        color: theme.chart.text
                    }
                }
            },
            yAxis: {
                gridLineWidth: 1,
                gridLineColor: theme.chart.gridLine,
                labels: {
                    style: {
                        color: theme.chart.text
                    }
                }
            },

            // subtitle: {
            //     style: {
            //         color: theme.chart.text
            //     },
            //     text: bmkEquation
            // },

            credits: {
                enabled: false
            },

            rangeSelector: {
                allButtonsEnabled: true,
                buttonTheme: { // styles for the buttons
                    fill: theme.chart.input,
                    // stroke: 'red',
                    'stroke-width': 0,
                    r: 8,
                    states: {
                        hover: {},
                        select: {
                            fill: theme.chart.text,
                            style: {
                                color: 'black'
                            }
                        }
                        // disabled: { ... }
                    }
                },
                inputStyle: {
                    color: theme.chart.text
                },
                labelStyle: {
                    color: theme.chart.text,
                    fontWeight: 'bold'
                }
            },
            title: {
                style: {
                    color: theme.chart.text,
                },
                text: props.title
            },

            series: chartSeries,

            legend: {
                layout: "horizontal",
                  align: "left",
                  useHTML: true,
                  maxHeight: 60,
                  frontcolor:'white',
                  enabled: true,
                  itemStyle: {
                    color: 'white',
                    fontWeight: 'bold'
                }
            },
            }
    }, [theme, props.title]);

    const [chartOptions, setChartOptions] = useState(getChart([0,0]));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (props.series === [0, 0]) return;
        setIsLoading(true);
        setChartOptions(getChart(props.series));
        setIsLoading(false);

    }, [props.series, theme]);


    return (
            <React.Fragment>
            <Container maxWidth={false} disableGutters={true}>
                {isLoading ? <Container style={{minHeight: 800, textAlign: "center"}}><CircularIndeterminate/></Container>
                    :
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={chartOptions}
                        containerProps={{style: {width: "100%"}}}
                    /> }                   
            </Container>
        </React.Fragment>
    );
}
