import * as React from 'react';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: 'black'
        //  overflow: 'auto',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 'auto',
        minWidth: 200,
    },
    panelSummary: {
        flexDirection: "row-reverse"
    },
}));


export default function InformationArea(props) {
    const classes = useStyles();

    const [fileName, setFileName] = React.useState('');
    const [chartTitle, setChartTitle] = React.useState('');

    const handleFileNameChange = (aFileName, title) => {
        setFileName(aFileName);
        setChartTitle(title);
    }

    const [age, setAge] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    return (
        <div>
            <Container maxWidth="xl" className={classes.container}>
                {/*<Grid container spacing={6}>*/}
                {/*    <Grid item xs={12}>*/}
                {/*        <DropdownsBar bucket={props.folderName} fileNameCallback={(aFileName, chartText) => handleFileNameChange(aFileName, chartText)}/>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}

                {/*<Typography variant="h6" component="h6">*/}
                {/*    Let our AI Custom build your portfolio... (4 questions)*/}
                {/*</Typography>*/}
                {/*<TextField id="standard-basic" label="Initial Investment" variant="standard"/>*/}
                {/*<FormControl fullWidth>*/}
                {/*    <InputLabel id="demo-simple-select-label">Age</InputLabel>*/}
                {/*    <Select*/}
                {/*        labelId="demo-simple-select-label"*/}
                {/*        id="demo-simple-select"*/}
                {/*        value={age}*/}
                {/*        label="Age"*/}
                {/*        onChange={handleChange}*/}
                {/*    >*/}
                {/*        <MenuItem value={10}>Ten</MenuItem>*/}
                {/*        <MenuItem value={20}>Twenty</MenuItem>*/}
                {/*        <MenuItem value={30}>Thirty</MenuItem>*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}


            </Container>
            {/*<Container maxWidth="xl" className={classes.container}>*/}
            {/*    <Grid container spacing={3}>*/}
            {/*        <Grid item xs={7}>*/}
            {/*            <Paper className={classes.paper}>*/}
            {/*                <PerformanceSummaryTable bucket={props.folderName} vals={fileName}/>*/}
            {/*            </Paper>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Container>*/}
            {/*<Container maxWidth="xl" className={classes.container}>*/}
            {/*    /!*<Grid item xs={9}>*!/*/}
            {/*        <Paper className={classes.paper}>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                <Chart bucket={props.folderName} vals={fileName} title={chartTitle}/>*/}
            {/*            </Grid>*/}
            {/*        </Paper>*/}
            {/*    /!*</Grid>*!/*/}
            {/*</Container>*/}
            {/*<Container maxWidth="xl" className={classes.container}>*/}
            {/*    <Grid container spacing={3}>*/}
            {/*        <Grid item xs={12}>*/}
            {/*            <Paper className={classes.paper}>*/}
            {/*                <MonthlyPnlTable bucket={props.folderName} vals={fileName}/>*/}
            {/*            </Paper>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Container>*/}
            {/*<Container maxWidth="xl" className={classes.container}>*/}
            {/*    <Accordion style={{width : '50%', backgroundColor: 'black'}}>*/}
            {/*        <AccordionSummary className={classes.panelSummary}*/}
            {/*            expandIcon={<ExpandMoreIcon/>}*/}
            {/*            aria-controls="panel1a-content"*/}
            {/*            id="panel1a-header"*/}
            {/*        >*/}
            {/*            &nbsp;&nbsp;&nbsp;*/}
            {/*            <Typography component="h2" variant="h6">Performance and Risk Summary</Typography>*/}
            {/*        </AccordionSummary>*/}
            {/*        <AccordionDetails>*/}
            {/*            <StatsSummaryTable bucket={props.folderName} vals={fileName}/>*/}
            {/*        </AccordionDetails>*/}
            {/*    </Accordion>*/}
            {/*</Container>*/}
            {/*<Container maxWidth="xl" className={classes.container}>*/}
            {/*    <Accordion style={{width : '50%', backgroundColor: 'black'}}>*/}
            {/*        <AccordionSummary className={classes.panelSummary}*/}
            {/*            expandIcon={<ExpandMoreIcon/>}*/}
            {/*            aria-controls="panel2a-content"*/}
            {/*            id="panel2a-header"*/}
            {/*        >*/}
            {/*            &nbsp;&nbsp;&nbsp;*/}
            {/*            <Typography component="h2" variant="h6">Statistics</Typography>*/}
            {/*        </AccordionSummary>*/}
            {/*        <AccordionDetails>*/}
            {/*            <StatisticsTable bucket={props.folderName} vals={fileName}/>*/}
            {/*        </AccordionDetails>*/}
            {/*    </Accordion>*/}
            {/*</Container>*/}
            <br/>
        </div>
    );
}


