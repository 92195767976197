import React from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import MonthlyPnlTable from "./MonthlyPnlTable";
import BmkMonthlyTable from "./BmkMonthlyTable";
import Chart from "./Chart";
import SpyderChart from "./SpyderChart";
import PieChart from "./PieChart";
import PerformanceSummaryTable from "./PerformanceSummaryTable";
import StatsSummaryTable from "./StatsSummaryTable";
import StatisticsTable from "./StatisticsTable";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DropdownsBar from "./DropdownsBar";
import Typography from "@material-ui/core/Typography";
import BotConfigTable from "./BotConfigTable";
import BarChart from "./BarChart";
import ExBenchmarkStatsTable from './ExBenchmarkStatsTable';
import RollingChart from "./RollingChart";
import FactorTable from "./FactorTable";
import FactorBetas from "./FactorBetas";


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: 'black'
        //  overflow: 'auto',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 'auto',
        minWidth: 200,
    },
    panelSummary: {
        flexDirection: "row-reverse"
    }
}));

export default function ViewArea(props) {
    const classes = useStyles();

    const [fileName, setFileName] = React.useState('');
    const [chartTitle, setChartTitle] = React.useState('');
    const [project, setProject] = React.useState('');
    const [port, setPort] = React.useState('');

    const handleProjectNameChange = (proj) => {
        setProject(proj);
    }

    const handlePortNameChange = (port) => {
        setPort(port);
    }

    const handleFileNameChange = (aFileName, title) => {
        setFileName(aFileName);
        setChartTitle(title);
    }

    return (
        <div>

            {/* Dropdown Selector  */}
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <DropdownsBar bucket={props.folderName}
                            fileNameCallback={(aFileName, chartText) => handleFileNameChange(aFileName, chartText)}
                            projNameCallback={(proj) => handleProjectNameChange(proj)}
                            portNameCallback={(port) => handlePortNameChange(port)} 
                            authToken={props.authToken}/>
                    </Grid>
                </Grid>
            </Container>

            {/* Bot Config Table  */}
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <BotConfigTable bucket={props.folderName} vals={fileName} project={project} port={port} authToken={props.authToken} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>


            {/* Portfolio Score */}
            <Container maxWidth="xl" className={classes.container}>
                <Paper className={classes.paper}>
                    <Grid className={classes.paper} container alignItems="center" direction="row">
                        <Grid
                            item
                            container
                        // direction="column"
                        // justify="center"
                        // alignItems="center"
                        >
                            <SpyderChart bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            
            {/* Portfolio Allocations Charts*/}
            <Container maxWidth="xl" className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography component="h2" variant="h6">Portfolio Allocation</Typography>
                    &nbsp;&nbsp;&nbsp;
                    <Grid item xs={12} style={{ display: "flex", gap: "1rem" }}>
                        <PieChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} type={'Sector'} fetchfn={'fetchBotConfigGroupings'} />
                        <PieChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} type={'Industry'} fetchfn={'fetchBotConfigGroupings'} />
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid item xs={12} style={{ display: "flex", gap: "1rem" }}>
                        <PieChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} type={'AssetClass'} fetchfn={'fetchBotConfigGroupings'} />
                        <PieChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} type={'Securities'} fetchfn={'fetchBotConfigGroupings'} />
                    </Grid>
                </Paper>
            </Container>


            {/* Portfolio Styles Chart*/}
            <div id="div_id_style"></div>
            <Container maxWidth="xl" className={classes.container}>
                {/*<Grid item xs={9}>*/}
                <Paper className={classes.paper}>
                    <Typography component="h2" variant="h6">Portfolio Style Exposures</Typography>
                    &nbsp;&nbsp;
                    <Grid item xs={12}>
                        <BarChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} type={'Style'} fetchfn={'fetchAggPortTheme'} ylabel={"Score"} />
                    </Grid>
                </Paper>
                {/*</Grid>*/}
            </Container>


            {/* Performance Summary Table */}
            <div id="div_id_perf"></div>
            <Container maxWidth="xl" className={classes.container}>
                {/*<Grid item xs={9}>*/}
                <Paper className={classes.paper}>
                    <Grid item xs={12}>
                        <PerformanceSummaryTable authToken={props.authToken} bucket={props.folderName} vals={fileName} project={project} port={port} />
                    </Grid>
                </Paper>
                {/*</Grid>*/}
            </Container>


            {/* Cumulative Performance Chart */}
            <Container maxWidth="xl" className={classes.container}>
                {/*<Grid item xs={9}>*/}
                <Paper className={classes.paper}>
                    <Grid item xs={12}>
                        <Chart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} />
                    </Grid>
                </Paper>
                {/*</Grid>*/}
            </Container>

            {/* Monthly Performance Table */}
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <MonthlyPnlTable authToken={props.authToken} bucket={props.folderName} vals={fileName} project={project} port={port} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>


            {/* Bmk Monthly Performance Table */}
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <BmkMonthlyTable authToken={props.authToken} bucket={props.folderName} vals={fileName} project={project} port={port} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>


            {/* Stats Performance and Risk Table */}
            <div id="div_id_risk"></div>
            <Container maxWidth="xl" className={classes.container}>
                <Accordion style={{ width: '75%', backgroundColor: 'black' }}>
                    <AccordionSummary className={classes.panelSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        &nbsp;&nbsp;
                        <Typography component="h2" variant="h6">Performance and Risk Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StatsSummaryTable authToken={props.authToken} bucket={props.folderName} vals={fileName} project={project} port={port} />
                    </AccordionDetails>
                </Accordion>
            </Container>


            {/* Stats Performance and Risk Table */}
            <div id="div_id_perfbmk"></div>
            <Container maxWidth="xl" className={classes.container}>
                <Accordion style={{ width: '75%', backgroundColor: 'black' }}>
                    <AccordionSummary className={classes.panelSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        &nbsp;&nbsp;
                        <Typography component="h2" variant="h6">Portfolio vs Benchmark Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ExBenchmarkStatsTable authToken={props.authToken} bucket={props.folderName} vals={fileName} project={project} port={port} />
                    </AccordionDetails>
                </Accordion>
            </Container>



            {/* Rolling Alpha Chart */}
            <Container maxWidth="xl" className={classes.container}>
                <Accordion style={{ width: '100%', backgroundColor: 'black' }}>
                    <AccordionSummary className={classes.panelSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        &nbsp;&nbsp;
                        <Typography component="h2" variant="h6">Alpha Chart</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <RollingChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={'Alpha'} project={project} port={port} type={'Alpha'} />
                    </AccordionDetails>
                </Accordion>
            </Container>

            {/* Rolling Beta Chart */}
            <Container maxWidth="xl" className={classes.container}>
                <Accordion style={{ width: '100%', backgroundColor: 'black' }}>
                    <AccordionSummary className={classes.panelSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        &nbsp;&nbsp;
                        <Typography component="h2" variant="h6">Beta Chart</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <RollingChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={'Beta'} project={project} port={port} type={'Beta'} />
                    </AccordionDetails>
                </Accordion>
            </Container>

            {/* Factor Analysis*/}
            <div id="div_id_factor"></div>
            <Container maxWidth="xl" className={classes.container}>
                <Accordion style={{ width: '50%', backgroundColor: 'black' }}>
                    <AccordionSummary className={classes.panelSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        &nbsp;&nbsp;
                        <Typography component="h2" variant="h6">Factor Analysis</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FactorTable authToken={props.authToken} bucket={props.folderName} vals={fileName} project={project} port={port} />
                    </AccordionDetails>
                </Accordion>
            </Container>

             {/* Factor Beta Analysis*/}            
             <div id="div_id_factor"></div>
             <Container maxWidth="xl" className={classes.container}>
                <Accordion style={{ width: '100%', backgroundColor: 'black' }}>
                    <AccordionSummary className={classes.panelSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        &nbsp;&nbsp;
                        <Typography component="h2" variant="h6">Factor Beta Chart</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FactorBetas authToken={props.authToken}bucket={props.folderName} vals={fileName} project={project} port={port} />
                    </AccordionDetails>
                </Accordion>
            </Container>


            {/* Contribution to Performance Table */}
            <Container maxWidth="xl" className={classes.container}>
                    <Accordion style={{ width: '75%', backgroundColor: 'black' }}>

                        <AccordionSummary className={classes.panelSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >    &nbsp;&nbsp;
                            <Typography component="h2" variant="h6">Contribution to Performance</Typography>
                        </AccordionSummary>

                        <AccordionDetails>

                            <Container maxWidth="xl" className={classes.container}>
                                <Paper className={classes.paper}>

                                    <BarChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} type={'AssetClass'} fetchfn={'fetchBotConfigGroupingsPerf'} ylabel={"Annualized Return"} />
                                    &nbsp;&nbsp;
                                    <BarChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} type={'Sector'} fetchfn={'fetchBotConfigGroupingsPerf'} ylabel={"Annualized Return"} />
                                    &nbsp;&nbsp;
                                    <BarChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} type={'Industry'} fetchfn={'fetchBotConfigGroupingsPerf'} ylabel={"Annualized Return"} />
                                    &nbsp;&nbsp;
                                    <BarChart authToken={props.authToken} bucket={props.folderName} vals={fileName} title={chartTitle} project={project} port={port} type={'Securities'} fetchfn={'fetchBotConfigGroupingsPerf'} ylabel={"Annualized Return"} />

                                </Paper>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
            </Container>

           
            {/* Trade Stats Performance Table */}
            <Container maxWidth="xl" className={classes.container}>
                <Accordion style={{ width: '50%', backgroundColor: 'black' }}>
                    <AccordionSummary className={classes.panelSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        &nbsp;&nbsp;
                        <Typography component="h2" variant="h6">Trade Statistics</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StatisticsTable authToken={props.authToken} bucket={props.folderName} vals={fileName} project={project} port={port} />
                    </AccordionDetails>
                </Accordion>
            </Container>
            <br />
        </div >
    );
}


