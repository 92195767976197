import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import DataService from "../services/DataService";
import {CircularIndeterminate, numFormatElement, StyledTableCell} from "../Utils/listItems";
import TablePagination from '@material-ui/core/TablePagination';
import Container from "@material-ui/core/Container";

export default function TradeTable(props) {

    const [summaryData, setSummaryData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [recordCount, setRecordCount] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (props.vals !== "") {
            setIsLoading(true);
            DataService.fetchTradeTable(props.authToken,props.project, props.port)
                .then(user => {
                    return new Promise(resolve => setTimeout(() => resolve(user), 1000));
                })
                .then(res => {
                    setRecordCount(res.length);
                    return res.filter(function (el, index) {
                        let startIndex = page * rowsPerPage;
                        let endIndex = (startIndex + rowsPerPage) - 1;
                        return index >= startIndex && index <= endIndex;
                    })
                })
                .then(res => {
                    setSummaryData(res);
                    setIsLoading(false);
            });
        }
    }, [props, page, rowsPerPage]);

    return (
        <React.Fragment>
            <Title>Trades</Title>
            <Container maxWidth={false} disableGutters={true} style={{minHeight: 800}}>
            {isLoading ? <div style={{textAlign: "center"}}><CircularIndeterminate/></div>
                : <Table size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Market</StyledTableCell>
                            <StyledTableCell>TradeName</StyledTableCell>
                            <StyledTableCell>Entry Date</StyledTableCell>
                            <StyledTableCell>Entry Size</StyledTableCell>
                            <StyledTableCell>Scalar</StyledTableCell>
                            <StyledTableCell>Entry Price</StyledTableCell>
                            <StyledTableCell>Exit Date</StyledTableCell>
                            <StyledTableCell>Exit Size</StyledTableCell>
                            <StyledTableCell>Exit Price</StyledTableCell>
                            <StyledTableCell>Trade PL</StyledTableCell>
                            <StyledTableCell>Trade RPL</StyledTableCell>
                            <StyledTableCell>Rtn</StyledTableCell>
                            <StyledTableCell>CumL Rtn</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            summaryData.map((row, index) => (
                                <TableRow key={index}>
                                    <StyledTableCell>{row.market}</StyledTableCell>
                                    <StyledTableCell>{row.tradeName}</StyledTableCell>
                                    <StyledTableCell>{new Date(row.entryDate).toLocaleDateString()}</StyledTableCell>
                                    <StyledTableCell>{row.entrySize}</StyledTableCell>
                                    <StyledTableCell>{row.scalar}</StyledTableCell>
                                    <StyledTableCell>{numFormatElement(row.entryPrice, '$')}</StyledTableCell>
                                    <StyledTableCell>{new Date(row.exitDate).toLocaleDateString()}</StyledTableCell>
                                    <StyledTableCell>{row.exitSize}</StyledTableCell>
                                    <StyledTableCell>{numFormatElement(row.exitPrice, '$')}</StyledTableCell>
                                    <StyledTableCell>{numFormatElement(row.tradePL, '$')}</StyledTableCell>
                                    <StyledTableCell>{numFormatElement(row.tradeRPL, '$')}</StyledTableCell>
                                    <StyledTableCell>{numFormatElement(row.rtn, '%')}</StyledTableCell>
                                    <StyledTableCell>{numFormatElement(row.cumlRtn, '%')}</StyledTableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            }
            </Container>
            <TablePagination
                component="div"
                count={recordCount}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </React.Fragment>
    );
}