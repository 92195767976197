import React, { useEffect, useState, useCallback } from 'react';
import Title from './Title';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HC_more from 'highcharts/highcharts-more';
import Container from "@material-ui/core/Container";
import DataService from "../services/DataService";
import useTheme from "@material-ui/core/styles/useTheme";
import { CircularIndeterminate } from "../Utils/listItems";

HC_more(Highcharts);

export default function SpyderChart(props) {
    const theme = useTheme();


    var categoryLinks = {
        'Style':'<a href="#div_id_style" style="color:white;">Style</a>',
        'Performance':'<a href="#div_id_perf" style="color:white;">Performance</a>',
        'Risk':'<a href="#div_id_risk" style="color:white;">Risk</a>',
        'Forward Looking':'<a href="#div_id_factor" style="color:white;">Factor Forward Looking</a>',
        'Diversification':'<a href="#div_id_perf" style="color:white;">Diversification</a>',
    };

    const getChart = useCallback((port1Scores, port2Scores) => {
        return {
            chart: {
                polar: true,
                type: 'line',
                backgroundColor: theme.chart.background
            },

            accessibility: {
                description: 'Spyder Chart that shows how the portfolio ranks in various metrics.'
            },

            title: {
                text: '',
                style: {
                    color: theme.chart.text
                },
                x: -80
            },

            pane: {
                size: '80%'
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: ['Performance', 'Risk', 'Style', 'Diversification', 'Forward Looking'],
                tickmarkPlacement: 'on',
                lineWidth: 0,
                labels: {
                    allowOverlap: true,
                    style: {
                        color: theme.chart.text
                    },
                    formatter: function () {
                        return categoryLinks[this.value]
                    },
                    style: {
                        color: theme.chart.text
                    },

                }
            },

            yAxis: {
                gridLineInterpolation: 'polygon',
                lineWidth: 0,
                min: 0,
                labels: {
                    style: {
                        color: theme.chart.text
                    }
                }
            },

            tooltip: {
                shared: true,
                pointFormat: '<span style="color:{series.color}">{series.name}: <b>${point.y:,.0f}</b><br/>'
            },

            legend: {
                align: 'bottom',
                verticalAlign: 'bottom',
                layout: 'vertical',
                itemStyle: {
                    color: theme.chart.text
                },
            },

            series: [{
                name: 'VQ Custom Portfolio',
                data: [50000, 50000, 60000, 30000, 50000],
                pointPlacement: 'on',
                color:'green'
            },
            {
                name: 'Style Benchmark Portfolio',
                data: [30000, 30000, 55000, 30000, 40000],
                pointPlacement: 'on',
                color:'red'
            },

            {
                name: 'Market Benchmark Portfolio',
                data: [40000, 40000, 40000, 40000, 40000],
                pointPlacement: 'on',
                color:'blue'
            }
            ],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        },
                        pane: {
                            align: 'center',
                            size: '100%%'
                        }
                    }
                }]
            }
        }
    }, [theme, props.title]);

    const [chartOptions, setChartOptions] = useState(getChart([0, 0]));
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.project === "" || props.port === "") return;
        setIsLoading(true);
        setChartOptions(getChart(0, 0));
        setIsLoading(false);
    }, [props, getChart]);


    return (
        <React.Fragment>
            <Title>Portfolio Score</Title>
            <Container maxWidth={false} disableGutters={true}>
                {isLoading ? <Container style={{ minHeight: 800, textAlign: "center" }}><CircularIndeterminate /></Container>
                    :
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'chart'}
                        options={chartOptions}
                        containerProps={{ style: { width: "100%" } }}
                    />}
            </Container>
        </React.Fragment>
    );
}
