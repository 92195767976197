import React, {useCallback, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import DataService from "../services/DataService";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        backgroundColor: 'black'
        //  overflow: 'auto',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 'auto',
        minWidth: 200,
    },
}));

export default function DropdownsBar(props) {
    const classes = useStyles();

    const [metaData, setMetaData] = React.useState([]);
    const [project, setProject] = React.useState('');
    const [portfolio, setPortfolio] = React.useState('');
    const [projects, setProjects] = React.useState([]);
    const [portfolios, setPortfolios] = React.useState([]);
    const [filename, setFilename] = React.useState('');
    const [chartTitle, setChartTitle] = React.useState('Portfolio Cumulative Performance');

    const handlePortfolioChange = (event) => {
        setPortfolio(event.target.value);
    };

    const handleProjectChange = (event) => {
        setProject(event.target.value);
        setPortfolio('');        
    };

    const updatePorts = useCallback(() => {
        let newSet = new Set(metaData.filter((data) => data.Project === project).map((data) => data.Portfolio));
        setPortfolios([...newSet].map((data) => {
            let m = {};
            m["label"] = data
            m["value"] = data
            return m
        }));
        // setProject(projects[0]);
        // setPortfolio(portfolios[0]);
    },[ project, metaData ]);

    useEffect(() => { // @Sundar Is updatePorts running twice? I already have a callback declared above with the same inputs
        updatePorts();
    }, [ project, metaData ]);

    useEffect(() => {
        if (portfolios.length === 0) return;
        setPortfolio(portfolios[0].value);
    }, [ portfolios ]);


    const handleExplore = () => {
        let metaDataElem = metaData.filter((data) =>
            data.Project === project
            && data.Portfolio === portfolio)
        if (metaDataElem.length > 0) {
            setChartTitle("Portfolio vs Benchmark")
            // setFilename(metaDataElem[0].File);
        }
    };


    useEffect(() => {
        if (props.authToken === '') return;
        DataService.fetchMetaData3(props.authToken)
            .then(res => {
                let newSet = new Set(res.Table.map((data) => data.Project));
                setMetaData(res.Table);
                setProjects([...newSet].map((data) => {
                    let m = {};
                    m["label"] = data
                    m["value"] = data
                    return m
                }));

                    //setProject(res.Table[0].Project);
                    //setPortfolio(res.Table[0].Portfolio);
                 setProject("VQ Custom Styles");
                 setPortfolio("Moderate");

            })
    }, [props.authToken]);

  
    useEffect(() => {
        props.fileNameCallback(filename, chartTitle);
    }, [filename, props, chartTitle]);

    useEffect(() => {
        props.projNameCallback(project);
    }, [ props, project ]);

    useEffect(() => {
        props.portNameCallback(portfolio);
    }, [ props, portfolio ]);

    useEffect(() => {
        updatePorts();
    }, [project, metaData ]);

    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <TextField className={classes.textField}
                           id="standard-select-projects"
                           select
                           label="Theme"
                           value={project}
                           onChange={handleProjectChange}
                >
                    {projects.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField className={classes.textField}
                           id="standard-select-portfolios"
                           select
                           label="Portfolio"
                           value={portfolio}
                           onChange={handlePortfolioChange}
                >
                    {portfolios.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                &nbsp;
                &nbsp;
                &nbsp;
                <Button style={{marginTop: '6px'}} variant="contained" color="secondary" onClick={() => handleExplore()}>
                    Explore
                </Button>
            </Paper>
        </React.Fragment>

    );
}
