import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DataService from "../services/DataService";
import {numFormatElement, StyledTableCell} from "../Utils/listItems";
import TableCell from "@material-ui/core/TableCell";

export default function FactorTable(props) {

    const [factorData, setFactorData] = useState([]);

    useEffect(() => {
        if (props.project !== "" && props.port !== ""  ) {
            DataService.fetchFactorTable(props.authToken,props.project, props.port)
                .then(res => setFactorData(res.modelFitTable));
        }
    }, [props]);

    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell style={{textAlign: 'left'}}>Factor</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Estimate</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>SE</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>tStat</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>pValue</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {factorData.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{row.Metric}</TableCell>
                            <TableCell>{numFormatElement(row.Estimate)}</TableCell>
                            <TableCell>{numFormatElement(row.SE)}</TableCell>
                            <TableCell>{numFormatElement(row.tStat)}</TableCell>
                            <TableCell>{numFormatElement(row.pValue)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
