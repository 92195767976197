import React, { useEffect, useState } from 'react';
import DataService from "../services/DataService";
import useTheme from "@material-ui/core/styles/useTheme";
import BaseChart from './BaseChart';
import DataUtils from '../Utils/DataUtils';
export default function FactorBetas(props) {
    const theme = useTheme();

    const [chartDataSeries, setChartDataSeries] = useState([0, 0]);

    useEffect(() => {
        if (props.project === "" || props.port === "" || props.authToken == "") return;

        DataService.fetchFactorTable(props.authToken,props.project, props.port)

            .then(res => {
                setChartDataSeries(DataUtils.createJSONArrayTS(res.factorBeta));
            });
    }, [props, theme]);


    return (
        <BaseChart bucket={props.folderName} vals={props.vals} title={""} project={props.project} port={props.port} series={chartDataSeries} />
    );
}
