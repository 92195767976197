import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';


export default function ParametersTable(props) {
  return (
    <React.Fragment>
      <Title>Alignment</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell>Style Preference 1</TableCell>
            <TableCell>{props.project}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Style Preference 2</TableCell>
            <TableCell>{props.project}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Style Preference 3</TableCell>
            <TableCell>{props.project}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Style Preference 4</TableCell>
            <TableCell>{props.project}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Risk Level</TableCell>
            <TableCell>Aggressive</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Time Horizon</TableCell>
            <TableCell>2 Years</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Equities</TableCell>
            <TableCell>100</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Fixed Income</TableCell>
            <TableCell>0</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
