import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import DataService from "../services/DataService";
import {numFormatElement, StyledTableCell} from "../Utils/listItems";
import Tooltip from '@material-ui/core/Tooltip';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";


const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: ".8em",
                fontStyle:'italic',
                border: "1px solid #E6E8ED",
            }
        }
    }
});

export default function PerformanceSummaryTable(props) {

    const [summaryData, setSummaryData] = useState([]);

    useEffect(() => {
        if (props.project === "" || props.port === ""  )  return;
            DataService.fetchPerfSummaryData(props.authToken,props.project, props.port)
                .then(res => setSummaryData(res));
    }, [props]);

    return (
        <React.Fragment>
            <Title>Performance Summary</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>&nbsp;</StyledTableCell>
                        <StyledTableCell>Since Inception</StyledTableCell>
                        {/*<MuiThemeProvider theme={theme}>*/}
                        <Tooltip title="vqAI: Exponentially time weighted metrics with preference to recent data.">
                        <StyledTableCell>Since Inception TW</StyledTableCell>
                        </Tooltip>
                        {/*</MuiThemeProvider>*/}
                        <StyledTableCell>Last 1 Year</StyledTableCell>
                        <StyledTableCell>Last 3 Years</StyledTableCell>
                        <StyledTableCell>Last 5 Years</StyledTableCell>
                    </TableRow>
                </TableHead>
                    <TableBody>
                        {summaryData.map((row, index) => (
                            <TableRow key={index}>
                                <StyledTableCell>{row.Calc}</StyledTableCell>
                                <StyledTableCell>{numFormatElement(row.SinceInception)}</StyledTableCell>
                                <StyledTableCell>{numFormatElement(row.SinceInceptionTimeWt)}</StyledTableCell>
                                <StyledTableCell>{numFormatElement(row.Last1Yr)}</StyledTableCell>
                                <StyledTableCell>{numFormatElement(row.Last3Yr)}</StyledTableCell>
                                <StyledTableCell>{numFormatElement(row.Last5Yr)}</StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
            </Table>
        </React.Fragment>
    );
}
