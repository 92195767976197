import React, {useEffect, useState} from 'react';
import DataService from "../services/DataService";
import useTheme from "@material-ui/core/styles/useTheme";
import BaseChart from './BaseChart';
import DataUtils from '../Utils/DataUtils';
export default function RollingChart(props) {
    const theme = useTheme();

       const [chartDataSeries, setChartDataSeries] = useState([0, 0]);


    useEffect(() => {
        if (props.project === "" || props.port === "" || props.type === "" )  return;

        DataService.fetchRollingAlphaBeta(props.authToken,props.project, props.port, props.type)

            .then(res => {    
            setChartDataSeries(DataUtils.createJSONArrayTS(res.Table));
            });
    }, [props, theme]);

   

    return (
        <BaseChart bucket={props.folderName} vals={props.vals} title={props.type} project={props.project} port={props.port} series={chartDataSeries} />
    );
}
