import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TradeTable from "./TradeTable";
import useTheme from "@material-ui/core/styles/useTheme";

export default function TradeDialog(props) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="contained" color="secondary" onClick={handleClickOpen}>
                View Trades
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent style={{backgroundColor: theme.chart.background}}>
                    <TradeTable authToken={props.authToken} project={props.project} port={props.port}/>
                </DialogContent>
                <DialogActions style={{backgroundColor: theme.chart.background}}>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>

    );
}
