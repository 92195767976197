import React, {useEffect, useState, useCallback} from 'react';
import Title from './Title';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import Container from "@material-ui/core/Container";
import DataService from "../services/DataService";
import useTheme from "@material-ui/core/styles/useTheme";
import {CircularIndeterminate} from "../Utils/listItems";


export default function PieChart(props) {
    const theme = useTheme();
    // const [chartTitle, setChartTitle] = useState('');

    const getChart = useCallback((data, title) => {
        return {
            chart: {
                plotBackgroundColor: theme.chart.background,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor: theme.chart.background
            },
            title: {
                text: title,
                style: {
                    color: theme.chart.text
                },
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        color: theme.chart.text,
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Sector',
                colorByPoint: true,
                data: data
            }]
        }}, [theme]);

    const [chartOptions, setChartOptions] = useState(getChart([0,0]));
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (props.project === "" || props.port === ""  ||  props.fetchfn !== "fetchBotConfigGroupings")  return;
        setIsLoading(true);
        DataService.fetchBotConfigGroupings(props.authToken,props.project, props.port, props.type)
            .then(res => {
                let data = [];
                let chartTitle = '';
                if (props.type === "Sector"){
                    // noinspection JSUnresolvedVariable
                    chartTitle = "Sector";
                    data = res.Sector;}

                if (props.type === "Industry"){
                    data = res.Industry;
                    chartTitle = "Industry";}

                if (props.type === "AssetClass"){
                    data = res.AssetClass;
                    chartTitle = "Asset Class";}

                if (props.type === "Securities"){
                    data = res.Securities;
                    chartTitle = "Securities";}

                if (props.type === "Theme"){
                    data = res.Theme;
                    chartTitle = "Theme";}
                setChartOptions(getChart(data, chartTitle))
            }).then( res => {
            setIsLoading(false);
        });
    }, [props, getChart]);

    useEffect(() => {
        if (props.project === "" || props.port === "" || props.fetchfn !== "fetchBotConfigGroupingsPerf" )  return;
        setIsLoading(true);
        DataService.fetchBotConfigGroupingsPerf(props.authToken,props.project, props.port, props.type)
            .then(res => {
                let data = [];
                let chartTitle = '';
                if (props.type === "Sector"){
                    // noinspection JSUnresolvedVariable
                    chartTitle = "Sector";
                    data = res.Sector;}
                if (props.type === "Industry"){
                    chartTitle = "Industry";
                    data = res.Industry;}
                if (props.type === "AssetClass"){ // AssetClass should be displayed with a space
                    chartTitle = "Asset Class";
                    data = res.AssetClass;}
                if (props.type === "Securities"){
                    chartTitle = "Securities";
                    data = res.Securities;}               
                if (props.type === "Style"){
                    chartTitle = "Style";
                    data = res;}
                // data =  data.map((data) =>
                //     // [data.name, data.values]
                //     [data.name, data.y]
                // );
                setChartOptions(getChart(data, chartTitle))
            }).then( res => {
            setIsLoading(false);
        });
    }, [props.project, props.port, props.fetchfn, props.type, getChart]);

    return (
        <React.Fragment>
            <Container maxWidth={false} disableGutters={true}>
                {isLoading ? <Container style={{minHeight: 800, textAlign: "center"}}><CircularIndeterminate/></Container>
                    :
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'chart'}
                        options={chartOptions}
                        containerProps={{style: {width: "100%" , fill: 'black'}}}
                    /> }
            </Container>
        </React.Fragment>
    );
}
