import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DataService from "../services/DataService";
import {numFormatElement, StyledTableCell} from "../Utils/listItems";
import TableCell from "@material-ui/core/TableCell";

export default function ExBenchmarkStatsTable(props) {

    const [ExBenchmarkStatsTable, setExBenchmarkStatsTable] = useState([]);

    useEffect(() => {
        if (props.project !== "" && props.port !== ""  && props.authToken !== "" ) {
            DataService.fetchExBmkStatsTable(props.authToken,props.project, props.port)
                .then(res => setExBenchmarkStatsTable(res));
        }
    }, [props.project, props.port]);

    return (
        <React.Fragment>
            <Table size="small">
            <TableHead>
                </TableHead>
                <TableBody>
                    {ExBenchmarkStatsTable.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{row.StatsName}</TableCell>
                            <TableCell>{numFormatElement(row.StatsVal)}</TableCell>
                            <TableCell>{row.riskName}</TableCell>
                            <TableCell>{numFormatElement(row.riskVal)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
