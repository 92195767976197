const DataService = {};

DataService.getOrigin = () => {
     return 'https://betavq.netlify.app';
    }

DataService.fetchMonthlyPerf = async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getMonthlyPerf/["${Project}","${Portfolio}"]`;
    console.log(url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchAggPortTheme = async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getAggPortTheme/["${Project}","${Portfolio}"]`;
    console.log(url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchPerfSummaryData = async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getSummaryPerformance/["${Project}","${Portfolio}"]`;
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchTradeTable= async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getTradeTable/["${Project}","${Portfolio}"]`;
    console.log(url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};


DataService.fetchMetaData3 = async (token) => {
    let url = `https://api.versaquant.com:5000/run3/pq.genPortSelectorOptions/["1","0"]`;
    // console.log('Checkout this URL! ', url)
    console.log(" fetchMetaData3 data token:", token)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            // 'Origin': `${DataService.getOrigin()}`,
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};


DataService.fetchStatsData =  async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getSummaryStats/["${Project}","${Portfolio}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchPerfSummaryStats =  async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getPerfSummaryStats/["${Project}","${Portfolio}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchBotConfig =  async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getBotConfig/["${Project}","${Portfolio}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchBotConfigGroupings =  async (token,Project, Portfolio, Type) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getPortGroupings/["${Project}","${Portfolio}","${Type}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchBotConfigGroupingsPerf =  async (token,Project, Portfolio, Type) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getPortGroupingsPerf/["${Project}","${Portfolio}","${Type}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchBmkReturns =  async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getBenchmarkPerf/["${Project}","${Portfolio}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchPortReturns =  async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getPortPerf/["${Project}","${Portfolio}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchRollingAlphaBeta =  async (token,Project,Portfolio,Type) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getRollingAlphaBeta/["${Project}","${Portfolio}","${Type}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchBmkMonthlyRtnTable =  async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getBmkMonthlyRtnTable/["${Project}","${Portfolio}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchBmkStatsTable =  async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getBmkStatsTable/["${Project}","${Portfolio}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};


DataService.fetchExBmkStatsTable =  async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getExBmkStatsTable/["${Project}","${Portfolio}"]`;
    // console.log('Checkout this URL! ', url)
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};

DataService.fetchFactorTable = async (token,Project, Portfolio) => {
    let url = `https://api.versaquant.com:5000/run3/pq.getFactorData/["${Project}","${Portfolio}"]`;
    const res = await fetch(url, {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        }
    });
    return await res.json();
};


// Old API calls 

DataService.fetchPnLData3 = async (fileName, folder) => {
    let url = `https://d8gmxtz58jynb.cloudfront.net/${folder}/${fileName.replace("PRECALC", "MOT501")}`;
    let url2 = `https://api.versaquant.com:5000/${folder}/${fileName.replace("PRECALC", "MOT501")}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            'Origin': 'https://api.versaquant.com:3000'
        }
    });
    return await res.json();
};

DataService.fetchPerfSummData = async (fileName, folder) => {
    let url = `https://d8gmxtz58jynb.cloudfront.net/${folder}/${fileName.replace("PRECALC", "RTN502")}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            'Origin': 'https://api.versaquant.com:3000'
        }
    });
    return await res.json();
};


DataService.fetchStatsSumData = async (fileName, folder) => {
    let url = `https://d8gmxtz58jynb.cloudfront.net/${folder}/${fileName.replace("PRECALC", "RTN503")}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            'Origin': 'https://api.versaquant.com:3000'
        }
    });
    return await res.json();
};

DataService.fetchStatsData_old = async (fileName, folder) => {
    let url = `https://d8gmxtz58jynb.cloudfront.net/${folder}/${fileName.replace("PRECALC", "SMS501")}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            'Origin': 'https://api.versaquant.com:3000'
        }
    });
    return await res.json();
};


DataService.fetchMetaData = async (folder) => {
    return fetch(`https://d8gmxtz58jynb.cloudfront.net/${folder}/QUniverseMetaTable.json`, {
        method: 'GET',
        headers: {
            'Origin': 'https://api.versaquant.com:3000'
        }
    }).then((res) => {
        let b = res.json()
        console.log(b)
        return b });

};


DataService.fetchTradeTableData = async (fileName, folder) => {
    let url = `https://d8gmxtz58jynb.cloudfront.net/${folder}/${fileName.replace("PRECALC", "TST501")}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            'Origin': 'https://api.versaquant.com:3000'
        }
    });
    return await res.json();
};

DataService.fetchPnLData = async (fileName, folder) => {
    let url = `https://d8gmxtz58jynb.cloudfront.net/${folder}/${fileName.replace("PRECALC", "MOT501")}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            'Origin': 'https://api.versaquant.com:3000'
        }
    });
    return await res.json();
};



DataService.fetchXMLList = async () => {

    const res = await fetch("https://vq-qexplorer-data.s3.us-east-2.amazonaws.com/?list-type=2&delimiter=/", {
        method: 'GET',
        headers: {
            'Origin': 'https://api.versaquant.com:3000'
        }
    });
    const res_1 = await res.text();
    const parser = new DOMParser();
    const xml = parser.parseFromString(res_1, 'text/xml');
    let elemArr = xml.getElementsByTagName("Prefix");
    let folderArray = [];
    for (let element of elemArr) {
        if (element.textContent !== "") {
            folderArray.push(parseInt(element.textContent));
        }
    }
    return Math.max(...folderArray);
};

export default DataService;