import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useAuth0 } from "@auth0/auth0-react";
import { AppBar } from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import Grid from "@material-ui/core/Grid";
import { Tabs } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import clsx from 'clsx';
import logo from '../Img/vsq-logo-white.png';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  container: {
    paddingTop: theme.spacing(24),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(44),
  },
  fixedHeight: {
    height: 840,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  subtitleLogin: {
    // fontStyle: 'italic',
    fontSize:16
  },

    greenButton: {
      background:'linear-gradient(to right bottom, #79CBDB, #79CBDB)',
      color: 'black',
      '&:hover': {
        background: 'linear-gradient(to right top,#79CBDB, white)',
          color: 'black'
      }
    }
}));



export default function Login() {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();
  const [open, setOpen] = React.useState(true);
  return (
    <div className={classes.container}>




      <CssBaseline />
      <AppBar style={{ backgroundColor: 'black' }} color="transparent"
        position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <Grid justify={"space-between"} container>
            <Grid xs={1} item>
              <img
                className={classes.logo}
                src={logo}
                border="0" height="33px" width="183px"
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Toolbar>
      </AppBar>

      <Typography variant="h4" component="h4">
        Portfolios
      </Typography>
      <Typography variant="h5" component="h5" >
      <span className={classes.subtitleLogin} style={{color: "#79CBDB"}}> Intelligent Investing </span>
      <span className={classes.subtitleLogin} >Simplified </span>
      <span className={classes.subtitleLogin} style={{color:  "#79CBDB"}}>.</span>
      </Typography>
      <br></br>
      <Button className={classes.greenButton}
        variant="contained" onClick={() => loginWithRedirect()}>
        Login
      </Button>
    </div>
  );
}
