import React, { useState } from 'react';
import {
    alpha,
    ThemeProvider,
    withStyles,
    makeStyles,
} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from "@material-ui/core/Typography";
import Slider from '@material-ui/core/Slider';
import { Checkbox, FormControlLabel, FormGroup, MenuItem } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 500,
        "& .MuiSlider-markLabel": {
            transform: "none"
        }
    },
    margin: {
        margin: theme.spacing(3),
        height: theme.spacing(3),
    }
}));
const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#79CBDB',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#79CBDB',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#79CBDB',
            },
        },
    },
})(TextField);


const timeHorizonMarks = [
    {
        value: 1,
        label: '1Y',
    },
    {
        value: 5,
        label: '5Y',
    },
    {
        value: 10,
        label: '10Y',
    },
    {
        value: 15,
        label: '15Y+ ',
    },
];
const investorTypeMarks = [
    {
        value: 1,
        label: 'Conservative',
    },
    {
        value: 3,
        label: 'Moderate',
    },
    {
        value: 5,
        label: 'Aggressive',
    },
];
const valuegrowthMarks = [
    {
        value: 1,
        label: 'Value',
    },
    {
        value: 100,
        label: 'Growth',
    },
];
const marketMarks = [
    {
        value: 1,
        label: 'Low',
    },
    {
        value: 100,
        label: 'High',
    },
];
const momentumMarks = [
    {
        value: 1,
        label: 'Low',
    },
    {
        value: 100,
        label: 'High',
    },
];
const sizeMarks = [
    {
        value: 1,
        label: 'Small',
    },
    {
        value: 100,
        label: 'Big',
    },
];
export default function CustomizedInputs() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        freq: '',
    });
    const [category, setCategory] = React.useState(10);

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const handleChange2 = (event) => {
        let value = event.target.value;
        setCategory(value);
    };

    const history = useHistory();
    const navigateTo = () => history.push('/explorer');

    return (
        <>
            <div>
                <br />
                <br />
                <Paper style={{ backgroundColor: '#000000', width: '60%' }} >
                    <Typography component="h2" variant="h6" color="black" noWrap className={classes.title}>
                        <p>What are your investment preferences?</p>
                    </Typography>
                    <Typography component="body1" variant="span" color="black" noWrap className={classes.title}>
                        <p><em>What is your investment time horizon?</em></p>
                    </Typography>
                    <div className={classes.root}>
                        <Slider
                            defaultValue={5}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider-always"
                            step={1}
                            marks={timeHorizonMarks}
                            valueLabelDisplay="auto"
                            min={1}
                            max={15}
                            color="secondary"
                        />
                    </div>
                    <Typography component="body1" variant="span" color="black" noWrap className={classes.title}>
                        <p><em>What is your risk preference?</em></p>
                    </Typography>
                    <div className={classes.root}>
                        <Slider
                            defaultValue={3}
                            aria-labelledby="discrete-slider-always"
                            step={1}
                            marks={investorTypeMarks}
                            valueLabelDisplay="off"
                            min={1}
                            max={5}
                            color="secondary"
                        />
                    </div>
                </Paper>

                <br />
                <br />

                <Paper style={{ backgroundColor: '#000000', width: '60%' }}>
                    <Typography component="h2" variant="h6" color="black" noWrap className={classes.title}>
                        <p>Which investment areas align with your investment goals?</p>
                        <FormControl style={{ width: '200px' }}>
                            <InputLabel id="demo-simple-select-label">Styles</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={category}
                                label="Category"
                                onChange={handleChange2}
                            >
                                <MenuItem value={10}>Values</MenuItem>
                                <MenuItem value={20}>Mega Trends</MenuItem>
                                <MenuItem value={30}>Index</MenuItem>
                                <MenuItem value={40}>Sectors</MenuItem>
                                <MenuItem value={50}>Dividend</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <br />
                        {category === 10 && <FormGroup>
                            <FormControlLabel control={<Checkbox />} label='Clean Air' />
                            <FormControlLabel control={<Checkbox />} label='Clean Energy' />
                            <FormControlLabel control={<Checkbox />} label='Clean Water' />
                            <FormControlLabel control={<Checkbox />} label='Corporate Culture' />
                            <FormControlLabel control={<Checkbox />} label='Gender Equality' />
                            <FormControlLabel control={<Checkbox />} label='Global Sustainable Development' />
                            <FormControlLabel control={<Checkbox />} label='Ocean Life' />
                            <FormControlLabel control={<Checkbox />} label='Racial Equality' />
                            <FormControlLabel control={<Checkbox />} label='Women Empowerment' />
                        </FormGroup>}


                        {category === 20 && <FormGroup>
                            <FormControlLabel control={<Checkbox />} label="Cloud Computing" />
                            <FormControlLabel control={<Checkbox />} label="AI & Analytics" />
                            <FormControlLabel control={<Checkbox />} label="5G & Internet of Things" />
                            <FormControlLabel control={<Checkbox />} label="CyberSecurity" />
                            <FormControlLabel control={<Checkbox />} label="Future Innovators" />
                            <FormControlLabel control={<Checkbox />} label="Genomics Immunology and Healthcare" />
                            <FormControlLabel control={<Checkbox />} label="Nano and Materials Innovation" />
                            <FormControlLabel control={<Checkbox />} label="R&D Leaders" />
                            <FormControlLabel control={<Checkbox />} label="Robotics" />
                            <FormControlLabel control={<Checkbox />} label="AI Technology" />
                            <FormControlLabel control={<Checkbox />} label="Self-Driving EV and Tech" />
                            <FormControlLabel control={<Checkbox />} label="Supply Chain Logistics" />
                            <FormControlLabel control={<Checkbox />} label="Virtual Work and Life Multisector" />
                        </FormGroup>}

                        {category === 30 && <FormGroup>
                            <FormControlLabel control={<Checkbox />} label='ESG SmallCap' />
                            <FormControlLabel control={<Checkbox />} label='ESG MidCap' />
                            <FormControlLabel control={<Checkbox />} label='SmallCap' />
                            <FormControlLabel control={<Checkbox />} label='MidCap' />
                            <FormControlLabel control={<Checkbox />} label='LargeCap' />
                        </FormGroup>}


                        {category === 40 && <FormGroup>
                            <FormControlLabel control={<Checkbox />} label='Communication Services' />
                            <FormControlLabel control={<Checkbox />} label='Consumer Discretionary' />
                            <FormControlLabel control={<Checkbox />} label='Consumer Staples' />
                            <FormControlLabel control={<Checkbox />} label='Energy' />
                            <FormControlLabel control={<Checkbox />} label='Financials' />
                            <FormControlLabel control={<Checkbox />} label='Health Care' />
                            <FormControlLabel control={<Checkbox />} label='Industrials' />
                            <FormControlLabel control={<Checkbox />} label='Information Technology' />
                            <FormControlLabel control={<Checkbox />} label='Materials' />
                            <FormControlLabel control={<Checkbox />} label='Real Estate' />
                            <FormControlLabel control={<Checkbox />} label='Utilities' />
                        </FormGroup>}

                        {category === 50 && <FormGroup>
                            <FormControlLabel control={<Checkbox />} label='Dividend' />
                            <FormControlLabel control={<Checkbox />} label='Dividend REIT' />
                        </FormGroup>}

                    </Typography>
                </Paper>
                <Accordion style={{ backgroundColor: '#000000', width: '50%', padding: '5px 0 100px 50px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        padding='100px 0 100px 50px'
                    >
                        <Typography component="h2" variant="h6" color="black" noWrap className={classes.title}>
                            <p>Factor Performance Assumption</p> </Typography>
                    </AccordionSummary>
                    <Typography id="discrete-slider-always" gutterBottom>
                        <Typography align="center">Market  - 1yr</Typography>
                    </Typography>
                    <Slider

                        aria-labelledby="discrete-slider-always"
                        getAriaValueText={valuetext}
                        defaultValue={30}
                        marks={marketMarks}
                        step={1}
                        min={1}
                        max={100}
                        valueLabelDisplay="auto"
                        color="secondary"
                    />
                    <br>
                    </br>
                    <Typography id="discrete-slider-always" gutterBottom>
                        <Typography align="center">Value vs Growth - 1yr</Typography>
                    </Typography>
                    <Slider

                        aria-labelledby="discrete-slider-always"
                        getAriaValueText={valuetext}
                        defaultValue={30}
                        step={1}
                        marks={valuegrowthMarks}
                        color="secondary"

                    />
                    <br />
                    <Typography id="discrete-slider-always" gutterBottom>
                        <Typography align="center"> Momentum - 1yr</Typography>
                    </Typography>
                    <Slider
                        aria-labelledby="discrete-slider-alwaysr"
                        getAriaValueText={valuetext}
                        defaultValue={30}
                        step={1}
                        marks={momentumMarks}
                        min={1}
                        max={100}
                        valueLabelDisplay="auto"
                        color="secondary"
                    />
                    <br />
                    <Typography id="discrete-slider-always" gutterBottom>
                        <Typography align="center"> Size - 1yr</Typography>
                    </Typography>
                    <Slider

                        aria-labelledby="discrete-slider-always"
                        getAriaValueText={valuetext}
                        step={1}
                        defaultValue={30}
                        marks={sizeMarks}
                        color="secondary"
                    />
                </Accordion>
            </div>
            <div style={{ width: '50%', textAlign: "left" }}>
                <Button variant="contained" onClick={navigateTo}>Recommend
                    Portfolio</Button></div>
        </>

    );
}

function valuetext(value) {
    return `${value}`;
}
