import React, {useEffect} from 'react';
import MainPage from "./Routes/MainPage";
import Login from "./Routes/Login";
import {Route, Switch, Router} from 'react-router-dom';
import history from './Utils/history';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme} from "@material-ui/core/styles";
import DataService from "./services/DataService";
import Align from "./Routes/AlignPage";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}            
            {' '}
            <Link color="inherit" href="https://www.versaquant.com/">
                VersaQuant Inc.
            </Link>{' '}
            
        </Typography>
    );
}

export default function App() {
    const darkTheme = createMuiTheme({
        palette: {
            type: "dark",
            secondary: {
                main: '#7c838d'
            },
            background: {
                default: 'black'
              }
        },
        chart: {
            background: 'black',
            input: '#7c838d',
            text: 'white',
            gridLine: '#292b2d'
        },
        font: {
            positiveAmt: 'green',
            negativeAmt: 'red',
        },
        table: {
            header: {
                background: 'black',
                text: 'white',
            }
        }
    });
    const lightTheme = createMuiTheme({
        palette: {
            type: "light",
            secondary: {
                main: '#CDC9C3'
            }
        },
        chart: {
            background: 'white',
            input: '#CDC9C3',
            text: 'black',
            gridLine: '#555555'
        },
        font: {
            positiveAmt: 'green',
            negativeAmt: 'red',
        },
        table: {
            header: {
                background: 'white',
                text: 'black',
            }
        }
    });
    const [bucketName, setBucketName] = React.useState('');
    const [token, setToken] = React.useState('');
    const handleTokenChange = (t_token) => {
        setToken(t_token);
    }

    // useEffect(() => {
    //     DataService.fetchXMLList()
    //         .then(res => setBucketName(res));
    // }, []);

    useEffect(() => {
        setBucketName('NA');
    }, []);

    return (
        <ThemeProvider theme={darkTheme}>
        <div>
            <Router history={history} basename="/">
                <Switch>
                    <Route exact path="/" >
                        <Login />
                    </Route>                    
                    <Route path="/explorer">
                      <MainPage isDashboard={true} bucket={bucketName} 
                                tokenCallback={(t_token) => handleTokenChange(t_token)}/>
                    </Route>
                    <Route path="/align">
                      <Align isDashboard={true} bucket={bucketName} authToken={token}/>
                    </Route>
                </Switch>
            </Router>
            <Box pt={4}>
                <Copyright/>
            </Box>
            <br/>
            <br/>
            <br/>
        </div>
        </ThemeProvider>
    );
}
