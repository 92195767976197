import { keys } from 'highcharts';
import React from 'react';


const DataUtils = {};


DataUtils.createJSONArrayTS = (jsonRes) => {
    // Sumanth put code here.
    // You will need to transform the given json data and create a series object that HighChart can use.
    // jsonRes is a json representation of matlab table Ex: Table with cols = Time | Open | Close
    // Series is a json object that looks like below. 
    // Ex:   [
        //     {name: "Open",
            // data: OpenData},
            // {name: "Close",
            // data: CloseData} ]
    // OpenData is an array of Nx2 where 1st col is Time & 2nd is Open values.        

    let series = jsonRes;
    let finalSeries = [];
    /* for (let i=0;i<series.length; i++)
    {
        let obj = series[i];
        for (let KEY in obj)
        {
            this.finalSeries.push({[KEY] : obj[KEY]});
        }
    } */
    let keys = Object.keys(jsonRes[0]);
    keys = keys.filter(e => e !== 'Time')
    // 1. remove timepart from keys
    /* 2. jsonRes.filter 
    let chartDataMomentum = res.factorBeta.filter((data) => {
                    return data.Momentum != null;
                }).map((data) =>
                    [Date.parse(data.Time), data.Momentum]
                );
    
    */
   finalSeries = keys.map((KEY)=>
   jsonRes.filter((data)=> {
   return data[KEY]!= null}).map((data)=>[Date.parse(data.Time), data[KEY]])
   )
   return Object.keys(keys).map((id)=>{
   return {
    "name": keys[id],
    "data": finalSeries[id]
   }
   })
//return finalSeries;
}

export default DataUtils;