import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import DataService from "../services/DataService";
import {numFormatElement, StyledTableCell} from "../Utils/listItems";

export default function MonthlyPnlTable(props) {

    const [pnlData, setPnlData] = useState([]);

    useEffect(() => {
        if (props.project !== "" && props.port !== ""  && props.authToken !== "") {
            DataService.fetchMonthlyPerf(props.authToken,props.project, props.port)
                .then(res => setPnlData(res));
        }
    }, [props]);

    return (
        <React.Fragment>
            <Title>Portfolio Monthly Performance</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell style={{textAlign: 'center'}}>Year</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Jan</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Feb</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Mar</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Apr</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>May</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Jun</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Jul</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Aug</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Sep</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Oct</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Nov</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>Dec</StyledTableCell>
                        <StyledTableCell style={{position: 'relative', right: '1px'}}>YTD</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pnlData.length > 0 &&  pnlData.map((row, index) => (
                        <TableRow key={index}>
                            <StyledTableCell>{row.Year}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Jan, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Feb, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Mar, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Apr, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.May, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Jun, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Jul, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Aug, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Sep, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Oct, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Nov, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.Dec, '%')}</StyledTableCell>
                            <StyledTableCell>{numFormatElement(row.YTD, '%')}</StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
