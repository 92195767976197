import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import DataService from "../services/DataService";
import {numFormatElement, StyledTableCell, StyledTableCellLeftAlign} from "../Utils/listItems";

export default function BotConfigTable(props) {

    const [botConfigTable, setBotConfigTable] = useState([]);

    useEffect(() => {
        if (props.project !== "" && props.port !== "" && props.authToken !== "" ) {
            DataService.fetchBotConfig(props.authToken,props.project, props.port)
                .then(res => setBotConfigTable(res));
        }
    }, [props]);

    return (
        <React.Fragment>
            <Title>Portfolio</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell style={{textAlign: 'left'}}>Symbol</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Name</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Allocation</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Asset Class</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Sector</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Industry</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Instrument Type</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>VQ Theme</StyledTableCell>
                        {/*<StyledTableCell style={{textAlign: 'left'}}>Strategy</StyledTableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {botConfigTable.length > 0 &&  botConfigTable.map((row, index) => (
                        <TableRow key={index}>
                            <StyledTableCellLeftAlign>{row.Symbol}</StyledTableCellLeftAlign>
                            <StyledTableCellLeftAlign>{row.Name}</StyledTableCellLeftAlign>
                            <StyledTableCellLeftAlign>{numFormatElement(row.Allocation, '%')}</StyledTableCellLeftAlign>
                            <StyledTableCellLeftAlign>{row.AssetClass}</StyledTableCellLeftAlign>
                            <StyledTableCellLeftAlign>{row.Sector}</StyledTableCellLeftAlign>
                            <StyledTableCellLeftAlign>{row.Industry}</StyledTableCellLeftAlign>
                            <StyledTableCellLeftAlign>{row.InstrumentType}</StyledTableCellLeftAlign>
                            <StyledTableCellLeftAlign>{row.vqTheme}</StyledTableCellLeftAlign>
                            {/*<StyledTableCellLeftAlign>{row.Strategy}</StyledTableCellLeftAlign>*/}

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
