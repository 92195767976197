import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DataService from "../services/DataService";
import {numFormatElement, StyledTableCell} from "../Utils/listItems";
import TableCell from "@material-ui/core/TableCell";

export default function StatsSummaryTable(props) {

    const [summaryData, setSummaryData] = useState([]);

    useEffect(() => {
        if (props.project !== "" && props.port !== ""  ) {
            DataService.fetchPerfSummaryStats(props.authToken,props.project, props.port)
                .then(res => setSummaryData(res));
        }
    }, [props]);

    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell style={{textAlign: 'left'}}>Performance</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Portfolio</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Benchmark</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Risk</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Portfolio</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'left'}}>Benchmark</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {summaryData.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{row.StatsName}</TableCell>
                            <TableCell>{numFormatElement(row.StatsVal)}</TableCell>
                            <TableCell>{numFormatElement(row.bmkstatsVal)}</TableCell>
                            <TableCell>{row.riskName}</TableCell>
                            <TableCell>{numFormatElement(row.riskVal)}</TableCell>
                            <TableCell>{numFormatElement(row.bmkriskVal)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
