import React, {useEffect, useState, useCallback} from 'react';
import Title from './Title';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import Container from "@material-ui/core/Container";
import DataService from "../services/DataService";
import TradeDialog from "./TradeDialog";
import useTheme from "@material-ui/core/styles/useTheme";
import ParamsDialog from "./ParamsDialog";
import {CircularIndeterminate} from "../Utils/listItems";

export default function Chart(props) {
    const theme = useTheme();

    const getChart = useCallback((plotDataPort, plotDataBmk, bmkEquation ) => {
        return {
            chart: {
                height: 800,
                // width: 15000,
                type: 'line',
                backgroundColor: theme.chart.background,
            },
            xAxis: {
                gridLineWidth: 1,
                gridLineColor: theme.chart.gridLine,
                labels: {
                    staggerLines: 2,
                    style: {
                        color: theme.chart.text
                    }
                }
            },
            yAxis: {
                gridLineWidth: 1,
                gridLineColor: theme.chart.gridLine,
                labels: {
                    style: {
                        color: theme.chart.text
                    }
                }
            },

            subtitle: {
                style: {
                    color: theme.chart.text
                },
                text: bmkEquation
            },

            // plotOptions: {
            //     series: {
            //         compare: 'value',
            //         showInNavigator: true
            //     }
            // },
            //
            // tooltip: {
            //     pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
            //     valueDecimals: 2,
            //     split: true
            // },

            credits: {
                enabled: false
            },

            rangeSelector: {
                allButtonsEnabled: true,
                buttonTheme: { // styles for the buttons
                    fill: theme.chart.input,
                    // stroke: 'red',
                    'stroke-width': 0,
                    r: 8,
                    states: {
                        hover: {},
                        select: {
                            fill: theme.chart.text,
                            style: {
                                color: 'black'
                            }
                        }
                        // disabled: { ... }
                    }
                },
                inputStyle: {
                    color: theme.chart.text
                },
                labelStyle: {
                    color: theme.chart.text,
                    fontWeight: 'bold'
                }
            },
            title: {
                style: {
                    color: theme.chart.text,
                },
                text: props.title
            },
            series: [{
                    name: 'Portfolio',
                    data: plotDataPort

                },
                {
                    name: 'Benchmark',
                    data: plotDataBmk,
                    color: 'red'
                }],


            legend: {
                layout: "horizontal",
                  align: "left",
                  useHTML: true,
                  maxHeight: 60,
                  frontcolor:'white',
                  enabled: true,
                  itemStyle: {
                    color: 'white',
                    fontWeight: 'bold'
                }
            },
            }
    }, [theme, props.title]);

    const [chartOptions, setChartOptions] = useState(getChart([0,0]));
    const [isLoading, setIsLoading] = useState(true);
    const [chartDataPort, setChartDataPort] = useState([0,0]);
    const [chartDataBmk, setChartDataBmk] = useState([0,0]);
    const [chartSubTitle, setChartSubTitle] = useState('Benchmark Equation');

    useEffect(() => {
        if (props.project === "" || props.port === ""  ||  props.authToken === '')  return;
        setIsLoading(true);
        DataService.fetchPortReturns(props.authToken,props.project, props.port)
            .then(res => {
                let chartData = res.Table.filter((data) => {
                    return data.Returns != null;
                }).map((data) =>
                    [Date.parse(data.Time), data.Returns]
                );
                setChartDataPort(chartData);
                // setChartOptions(getChart(chartData))
            }).then( () => {
            setIsLoading(false);
        });
    }, [props]);

    useEffect(() => {
        if (props.project === "" || props.port === "" ||  props.authToken === '')  return;
        setIsLoading(true);
        DataService.fetchBmkReturns(props.authToken,props.project, props.port)
            .then(res => {
                let chartData = res.Table.filter((data) => {
                    return data.Returns != null;
                }).map((data) =>
                    [Date.parse(data.Time), data.Returns]
                );
                setChartDataBmk(chartData);
                setChartSubTitle(res.bmkEq);
                // setChartOptions(getChart(chartData))
            }).then( () => {
            setIsLoading(false);
        });
    }, [props]);

    useEffect(() => {
        setIsLoading(true);
        if (props.project === "" || props.port === "" || chartSubTitle === "" || chartDataBmk === [0,0] || chartDataPort === [0,0]  )  return;
        
        setChartOptions(getChart(chartDataPort, chartDataBmk, chartSubTitle));
        setIsLoading(false);
    }, [props, chartDataPort, chartDataBmk, chartSubTitle, getChart]);

    useEffect(() => {
        setIsLoading(true);
        // if (props.project === "" || props.port === "" )  return;       
    }, [props.project, props.port]);


    return (
            <React.Fragment>
            <Title>Cumulative Performance</Title>
            <Container maxWidth={false} disableGutters={true}>
                {isLoading ? <Container style={{minHeight: 800, textAlign: "center"}}><CircularIndeterminate/></Container>
                    :
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={chartOptions}
                        containerProps={{style: {width: "100%"}}}
                    /> }
                    <TradeDialog authToken={props.authToken} project={props.project} port={props.port}/>
                    &nbsp;
                    &nbsp;
                    <ParamsDialog authToken={props.authToken} project={props.project} port={props.port}/>
            </Container>
        </React.Fragment>
    );
}
